import { Controller } from "@hotwired/stimulus";
import {
  showCrisp,
  hideCrisp,
  showAxeptio,
  hideAxeptio,
} from "../plugins/trackers";
import { showOverlay, hideOverlay } from "../plugins/overlay";
import { debounce } from "../plugins/debounce";

const DEBOUNCE_TIME = 300;

// Connects to data-controller="products-filter"
export default class extends Controller {
  static targets = [
    "checkboxes",
    "checkbox",
    "form",
    "search",
    "counter",
    "wrapper",
  ];

  initialize() {
    this.debounceSubmit = debounce(() => this.submit(), DEBOUNCE_TIME);
  }

  toggleCheckboxes() {
    this.checkboxesTarget.classList.toggle("d-none");
  }

  submit() {
    showOverlay();
    this.formTarget.requestSubmit();
  }

  resetSearch() {
    this.searchTarget.value = "";
    this.submit();
  }

  updateCounterAndSubmit() {
    const size = this.checkboxTargets.filter(({ checked }) => checked).length;

    if (size > 0) {
      this.counterTarget.textContent = `${size}`;
      this.counterTarget.classList.remove("d-none");
    } else {
      this.counterTarget.classList.add("d-none");
    }

    this.submit();
  }

  showFullScreen() {
    this.wrapperTarget.classList.add("filter-full-screen");

    hideCrisp();
    hideAxeptio();
  }

  hideFullScreen() {
    this.wrapperTarget.classList.remove("filter-full-screen");

    showCrisp();
    showAxeptio();
  }

  clear() {
    this.checkboxTargets.forEach((el) => {
      el.checked = false;
    });
    this.updateCounterAndSubmit();
  }

  hideOverlay() {
    hideOverlay();
  }
}
